:root {
    --font-main: "Kanit", sans-serif;
    --txt: #fff;
    --mcolor: #ec1775;
    --scolor: #100F0F;
    --font-montser: "Montserrat", sans-serif;
}

.btn_wrap {
    display: flex;
    justify-content: center;
    width: 800px;
    margin: 0 auto 50px;
    max-width: 100%;
}

.ticket_container {
    background: url('../../images/resource/ticket_bg.png') center /cover no-repeat;
    position: relative;
    width: 100%;
    height: 100vh;
    padding: 0;
    z-index: 99999;
    overflow: auto;
}

.ticket_container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: -1;
}

.ticket_container>.select-quantity {
    flex-direction: column;
}

.payment.popup.open,
.ticket_container>.select-quantity.open{
    height: auto;
    overflow: auto;
    padding: 30px 0 0;
    position: relative;
    min-height: 100%;
    justify-content: flex-end;
}
.payment.popup.open .popup_ct{
    height: auto;
    padding: 50px 30px;
    max-height: inherit;
    margin: 0 auto;
    overflow: inherit;
}


.ticket_container>.select-quantity>.box_ticket_list {
    display: flex;
    flex-direction: column;
}

.ticket_container>.select-quantity>.box_ticket_list::-webkit-scrollbar {
    display: none;
}


.ticket_container>.select-quantity .btn_next,
.ticket_container>.select-quantity .btn_prev {
    cursor: pointer;
    position: relative;
    text-align: center;
    color: #fff;
    width: 340px;
    height: 60px;
    background: var(--mcolor);
    font-size: 28px;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 50px;
    box-sizing: border-box;
    transition: all ease 0.3s;
    letter-spacing: 0;
    line-height: 1em;
    font-weight: 700;
    border: 3px var(--mcolor) solid;
}

.ticket_container>.select-quantity .btn_prev {
    background: rgba(236, 23, 117, 0.08);
    color: var(--mcolor)
}

.ticket_container>.select-quantity .btn_prev a {
    color: var(--mcolor);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ticket_container>.payment .popup_btn_container {
    margin-top: 30px;
    display: flex;
}

.ticket_container>.result::-webkit-scrollbar {
    display: none;
}

.ticket_container>.result.payment {
    width: 1200px;
    margin: 0 auto;
    padding: 30px 15px;
}

.ticket_container>.result>.booking_van_phong {
    margin-bottom: 12px;
}

.result_sp {
    display: none;
}

.info_bank {
    padding: 15px 0;
    border-bottom: 1px solid #ccc;
    display: flex;
    align-items: center;
}

.info_bank .img {
    flex-shrink: 0
}

.info_bank .des {
    text-align: left;
    padding-left: 30px;
}

.info_bank .des p {
    font-size: 20px;
    margin: 0 0 15px;
    text-align: left;
    font-weight: 300;
    font-family: "Kanit", sans-serif;
}



@media screen and (min-width: 961px) {
    .ticket_container>.select-quantity .btn_prev:hover {
        background: var(--mcolor)
    }

    .ticket_container>.select-quantity .btn_prev:hover a {
        color: #fff;
    }

    .ticket_container>.select-quantity .btn_next:hover {
        background: rgba(236, 23, 117, 0.08);
    }
}

@media screen and (max-width: 960px) {
    .result_pc {
        display: none;
    }

    .result_sp {
        display: block;
        text-align: center;
        width: 100%;
    }

    .info_bank{flex-wrap: wrap;}
    .info_bank .des{width: 100%;padding: 0;}
    .info_bank .des p{display: block;padding: 0;font-size: 16px;}
}

@media(max-width: 500px) {
    .select-quantity.popup.open {
        position: relative;
        top: inherit;
        left: inherit;
    }

    .payment.popup.open .popup_ct{padding: 30px 0 80px;}

    .ticket_container {
        width: 100%;
        height: 100vh;
        overflow: auto;
    }

    .btn_wrap {
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }

    .ticket_container::before {
        width: 100vw;
        height: 100vw;
        top: 10vw;
        left: calc(50% - 50vw);
    }

    .ticket_container>.payment .popup_btn_container {
       flex-direction: column-reverse;
    }

    .ticket_container>.payment .popup_btn_container .btn_accept:first-child {
        margin-top: 20px;
    }

    .ticket_container>.select-quantity .btn_next {
        bottom: 0;
        margin-bottom: 30px;
    }
    .ticket_container>.select-quantity .btn_next,
    .ticket_container>.select-quantity .btn_prev{font-size: 20px;}
    .ticket_container>.select-quantity>.box_ticket_list {
        display: block !important;
        flex-direction: unset !important;
        max-width: calc(100% - 30px);
        padding: 30px 0 30px;
    }

    .ticket_container>.select-quantity>.box_ticket_list .name {
        text-align: center;
        font-size: 36px;
    }

    .result_sucess .bg span br,
    .popup_ct .popup_form p:first-child span br{display: none;}

    
}